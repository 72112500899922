/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */
import holographic from "@elegantstack/gatsby-theme-flexiblocks/src/homepage/fintech/assets/holographic.jpg";
import heroBg from "@elegantstack/gatsby-theme-flexiblocks/src/homepage/saas/assets/hexagonal.png";

export default {
    heroContainer: {
        position: `relative`,
        pt: [5, 6],
        '::before, ::after': {
            position: `absolute`,
            content: `" "`,
            width: [`full`, `90%`],
            height: `80%`,
            top: [`-10%`, 0],
            right: `50%`,
            transform: `translate(50%, 0)`,
            zIndex: -1
        },
        '::before': {
            borderRadius: `xl`,
            background: t => `linear-gradient(
          180deg,
          ${t.colors.omegaLighter} 0%,
          ${t.colors.omegaLight} 100%
        )`
        },
        '::after': {
            background: `url(${heroBg}) no-repeat right top`,
            backgroundSize: `50%`,
            opacity: 0.1
        }
    },

    heading: {
        position: `relative`,
        pt: [2, 4],
        '::before, ::after': {
            position: `absolute`,
            content: `" "`,
            width: [`full`, `90%`],
            height: `110%`,
            top: [`-10%`, 0],
            right: `50%`,
            transform: `translate(50%, 0)`,
            zIndex: -1
        }
    }
}
